import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Carousel,
    DeviceType,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
    Testimonial,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import socialPreview from '../../../static/social-previews/vivents.png';
import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

const CASE_ID = 'vivents';
const MODULE_NAME = 'vivents';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgBlack: '#000',
    bgGrayTestimonial: '#F9F9F9',
    whiteText: '#fff',
};

const splashVideo =
    'https://storage.googleapis.com/ackee-web-static/videos/case-studies/vivents/vivents_splash_video.mp4';

const team = [
    { department: 'references.case.team.management', names: ['Marek Elznic'] },
    {
        department: 'references.case.team.design',
        names: ['Roman Gordienko'],
    },
    {
        department: 'references.case.team.ux',
        names: ['Michal Mikolaj'],
    },
    {
        department: 'references.case.team.web',
        names: ['Jiří Čermák'],
    },
    {
        department: 'references.case.team.backend',
        names: ['Pavel Švagr', 'Karina Bagalážová', 'Zhanybek Sadvakassov', 'Michal Vlasák'],
    },
    {
        department: 'references.case.team.android',
        names: ['Petr Polívka', 'David Bilík', 'Eduard Ablekimov'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Rostislav Babáček', 'Lukáš Hromadník', 'Alexandr Grigoryev', 'Vladislav Komkov'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Martin Beránek'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Simona Bubeníková', 'Julia Furmanyuk', 'Kateřina Tomášková'],
    },
];

const Vivents = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero.png`,
                    style: { height: '100%', marginTop: '1.5rem' },
                    imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                },
            }}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero-bg.png`,
                imgStyle: { objectFit: 'cover', objectPosition: 'top' },
            }}
            background={`${colors.bgBlack} `}
            headerTheme="light"
            textColor="#fff"
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.E_COMMERCE,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.android',
                    'references.case.output.ios',
                    'references.case.output.web',
                    'references.case.output.backend',
                    'references.case.output.testing',
                    'references.case.output.devops',
                ],
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.LITTLE_OVER_ONE_HALF}
            content={[
                {
                    background: colors.bgBlack,
                    video: {
                        src: splashVideo,
                        width: 1080,
                        height: 1080,
                        style: {
                            objectFit: 'contain',
                            objectPosition: 'bottom center',
                        },
                    },
                },
                {
                    background: colors.bgBlack,
                    image: {
                        src: `${IMAGE_PATH}/01-1.jpg`,
                        imgStyle: {
                            objectPosition: 'bottom',
                            objectFit: 'cover',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight
            content={[
                {
                    background: colors.bgBlack,
                    image: {
                        src: `${IMAGE_PATH}/02-1.png`,
                        imgStyle: {
                            objectFit: 'cover',
                            objectPosition: 'center bottom',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="light"
            background={colors.bgBlack}
            textColor={colors.whiteText}
            content={{
                section: `case.${CASE_ID}.section5.name`,
                title: `case.${CASE_ID}.section5.title`,
                text: `case.${CASE_ID}.section5.text`,
            }}
        />
        <Carousel
            headerTheme="light"
            deviceType={DeviceType.MOBILE}
            background={colors.bgBlack}
            slides={[
                {
                    image: {
                        src: `${IMAGE_PATH}/03-1.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-2.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-3.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-4.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-5.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-6.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-7.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-8.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/03-9.png`,
                        alt: `case.${CASE_ID}.galleryTitles.mobileApp`,
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section8.name`,
                title: `case.${CASE_ID}.section8.title`,
                text: `case.${CASE_ID}.section8.text`,
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.LITTLE_OVER_ONE_HALF}
            content={[
                {
                    background: colors.bgBlack,
                    image: {
                        src: `${IMAGE_PATH}/04-1.png`,
                        imgStyle: {
                            objectPosition: 'top',
                            objectFit: 'cover',
                        },
                    },
                },
                {
                    background: colors.bgBlack,
                    image: {
                        src: `${IMAGE_PATH}/04-2.png`,
                        style: { height: '100%', marginTop: '4.5rem' },
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                },
            ]}
        />

        <Testimonial
            headerTheme="dark"
            background={colors.bgGrayTestimonial}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `references/case-studies/${CASE_ID}/testimonial.jpg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        />

        <Team headerTheme="dark" content={team} />
    </>
);

export default Vivents;
